import React, { useEffect, useState } from 'react';
import { OutlinedTextInput, Text } from 'library';
import { styled } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';

import { useSearchUser } from '../hooks';

import type { ActualLeanUser } from 'models';

export function SearchWorker({
    selectedWorker,
    setSelectedWorker,
    pay,
    setPay,
}: {
    selectedWorker: ActualLeanUser | null;
    setSelectedWorker: (worker: ActualLeanUser | null) => void;
    pay: string | null;
    setPay: (pay: string) => void;
}) {
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [options, setOptions] = useState<ActualLeanUser[]>([]);

    const { loading, data, send, controller } = useSearchUser({
        searchTerm,
        page,
        options: { sendImmediately: false },
    });

    useEffect(() => {
        if (searchTerm.length >= 3) {
            setPage(1);
            controller?.abort();
            send();
        } else {
            setOptions([]);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (page === 1) {
            setOptions(data?.results ?? []);
        } else {
            setOptions([...options, ...(data?.results ?? [])]);
        }
    }, [data?.results]);

    function loadMoreItems(e: React.UIEvent<HTMLElement>) {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
            if (data && page < data?.num_pages) {
                setPage((prev) => prev + 1);
                send();
            }
        }
    }

    return (
        <div>
            <Text>Select the worker and pay rate you would like to add to the shift:</Text>
            <Row>
                <Autocomplete
                    value={selectedWorker}
                    options={options ?? []}
                    onChange={(_, value) => setSelectedWorker(value)}
                    loading={loading}
                    onInputChange={(_, value) => setSearchTerm(value)}
                    renderInput={(params) => (
                        <OutlinedTextInput
                            {...params}
                            style={{ marginTop: 10 }}
                            variant="outlined"
                            helperText="Select workers to set for this appointment. (3 character minimum)"
                            placeholder="Click here to search for workers"
                            value={searchTerm}
                        />
                    )}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.id})`}
                    getOptionSelected={(option: ActualLeanUser, value: ActualLeanUser | null) =>
                        option?.id === value?.id
                    }
                    renderOption={(option: ActualLeanUser) => (
                        <>
                            <StyledOption>
                                <Text>
                                    {option.first_name} {option.last_name}
                                </Text>
                                <Text variant="caption" textStyle={{ paddingLeft: 5 }}>
                                    ({option.id})
                                </Text>
                            </StyledOption>
                            {options?.[-1]?.id === option.id && <Text variant="caption">Loading...</Text>}
                        </>
                    )}
                    ListboxProps={{
                        onScroll: loadMoreItems,
                    }}
                />
                <OutlinedTextInput
                    label="Pay rate"
                    type="number"
                    value={pay}
                    onChange={(e) => setPay(e.target.value)}
                    style={{ margin: 10, width: 100 }}
                    InputProps={{ startAdornment: '$' }}
                />
            </Row>
        </div>
    );
}

const Row = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
});

const StyledOption = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
});
