import { useClient } from 'shared';

import type { ActualLeanUser, PagingResult } from 'models';
import type { ExtendedOptions } from 'shared/commonHooks/useClient';

export interface LeanAppointment {
    id: number;
    start_date: string;
    days_times: string;
}

export interface PastHireRequest {
    ongoing_request_id: number;
    worker_id: number | null;
    appointment_id: number | null;
    appointment_date: Date | null;
    start_time: Date | null;
    end_time: Date | null;
    pay: string;
}

export function usePastAppointments({ ongoingRequestId }: { ongoingRequestId: number }) {
    return useClient<LeanAppointment[]>({ url: `internal/ongoing-request/${ongoingRequestId}/past-appointments/` });
}

export function useSearchUser({
    searchTerm,
    page,
    options,
}: {
    searchTerm: string;
    page: number;
    options?: ExtendedOptions;
}) {
    return useClient<PagingResult<ActualLeanUser>>({
        url: `internal/user-lookup/search/?search_term=${searchTerm}&page=${page}`,
        options,
    });
}

export function usePastHire({ options }: { options?: ExtendedOptions }) {
    return useClient<{ message: string } | null>({
        url: 'internal/ongoing-request/past-hire/',
        method: 'POST',
        options,
    });
}
